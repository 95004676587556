<template>
    <div class="user" @click="user_clicked()">
        <div class="image">
            <img :src="props.user.picture"/>
        </div>
        <div>
            <div class="name">{{ props.user.name }}</div>
            <div class="username">@{{ props.user.username }}</div>
        </div>
        <div v-if="store.username != props.user.username" class="left">
            <div class="icon" v-if="in_contact">
                <optionicon :size="'20px'" :style="{'height': '20px'}"/>
            </div>
            <div class="icon" v-else>
                <add_user :size="'20px'" :style="{'height': '20px'}"/>
            </div>
        </div>
    </div>

    <user_details v-if="show_details" :user="user" :in_contact="in_contact" 
        @close="show_details = false" @added_to_contact="in_contact = true"/>
    
    <user_option v-if="show_option" :user="user"
        @close="show_option = false"/>

</template>

<script setup>
import Localbase from 'localbase'
let db = new Localbase('tryraffia')
import { store } from '../../store/store';

import { ref } from 'vue';

import add_user from '../../icons/add_user.vue';
import optionicon from '../../icons/option.vue';
import user_details from './user_details.vue';
import user_option from './user_option.vue'

import { defineProps } from 'vue';
const props = defineProps(['user'])


const in_contact = ref(false)
const init = () => {
    db.collection('contacts').doc({'user_id': props.user.user_id}).get().then(doc => {
        if (doc) {
            in_contact.value = true
        }
    })
}
init()


const show_details = ref(false)
const show_option = ref(false)
const user_clicked = () => {
    if (store.username != props.user.username) {
        if (in_contact.value == true) {
            show_option.value = true
        } else {
            show_details.value = true
        }
    }
}

</script>

<style scoped>
    .user {
        margin: 10px -20px;
        padding: 10px 20px;

        display: flex;
        align-items: center;
        gap: 10px;

        transition: .2s;
    }
    .user:active {
        background-color: rgba(0, 0, 0, 0.1);
    }
    .image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
    }
    .image img {
        width: 40px;
    }
    .name {
        font-weight: 700;
    }
    .username {
        font-size: 14px;
        color: gray;
    }

    .left {
        margin-left: auto;
    }
    .icon {

        width: 30px;
        height: 30px;
        border-radius: 5px;
        /* fill: white; */
        transition: .2s;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .icon:active {
        background-color: #edeef1;
    }
</style>