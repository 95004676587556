<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="m294.922-450.001 227.846 227.847L480-180.001 180.001-480 480-779.999l42.768 42.153-227.846 
            227.847h485.077v59.998H294.922Z"/>
        </svg>


    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>