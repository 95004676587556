<template>
    <div class="popup">
        
        <swiper class="myswiper"
            :direction="'vertical'"
            :resistanceRatio="0" 
            :slideToClickedSlide="true"
            :slidesPerView="'auto'"
            autoHeight
            
            @swiper="swiper_ready"
            @activeIndexChange="slide_changed"
            >
            <swiper-slide>
                <div class="dark">
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="box">
                    <div class="head">
                        <div class="title">Contacts</div>
                        <div class="icons">
                            <div class="icon">
                                <search size="20px"/>
                            </div>
                            <div class="icon" @click="close_slider()">
                                <close size="20px"/>
                            </div>
                        </div>
                    </div>
                    <div class="main">
                        <div class="empty" v-if="contacts.length == 0">
                            <div class="icon">
                                <contact size="40px"/>
                            </div>
                            <div class="text">Your contact list is currently empty</div>
                        </div>

                        <user_vue v-for="user in contacts" :key="user.user_id" :user="user"/>
                    </div>
                </div>
            </swiper-slide>

        </swiper>


    </div>
</template>

<script setup>
import Localbase from 'localbase'
let db = new Localbase('tryraffia')

import { Swiper, SwiperSlide } from 'swiper/swiper-vue.mjs';

import search from '../../icons/search.vue';
import close from '../../icons/close.vue'
import contact from '../../icons/contacts.vue'

import user_vue from './user.vue'

import { defineEmits, ref } from 'vue';
const emit = defineEmits(['close'])


var swiper_instance = null;
function swiper_ready(s) {
    swiper_instance = s

    setTimeout(() => {
        swiper_instance.slideNext()
    }, 200);
}

function close_slider() {
    swiper_instance.slidePrev()
}

function slide_changed(s) {
    
    if (s.activeIndex == 0) {
        setTimeout(() => {
            emit('close')
        }, 200);
    }
}


const contacts = ref([])
const init = () => {
    db.collection('contacts').get().then(doc => {
        contacts.value = doc
    })
}
init()
</script>

<style scoped>
    
    
    .popup {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;

        width: 100%;

        background-color: rgba(0, 0, 0, 0.9);
        color: white;
    }
    .myswiper {

        height: 100vh;
    }
    .dark {
        height: 100vh;
    }
    .box {
        background-color: white;
        color: #333;
        height: 100vh;
        overflow: auto;

        display: flex;
        flex-direction: column;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .title {
        font-weight: 700;
    }
    .icons {
        display: flex;
        gap: 15px;
    }

    .main {
        flex: auto;

        overflow: auto;
        padding: 10px;
    }
    
    .empty {
        margin: 80px 0px;
        text-align: center;
        font-size: 12px;
        fill: #333;
    }
    .text {
        margin-top: 5px;
        color: gray;
    }
</style>