<template>
    <div class="">
        <svg class="circle-loader progress" :width="size" :height="size" 
            :class="[color == 'white' ? 'white' : '']"
            version="1.1" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" :r="radius"></circle>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            default: '40'
        },
        radius: {
            default: '15'
        },
        color: {
            default: 'main'
        }
    }
}
</script>

<style scoped>

    .circle-loader {
        margin-left: calc(50% - 20px);  
        fill: transparent;
        stroke: var(--main);
        stroke-width: 2;
        animation: dash 2s ease infinite, rotate 2s linear infinite;
    }
    .white {
        stroke: white;
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1,95;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 85,95;
            stroke-dashoffset: -25;
        }
        100% {
            stroke-dasharray: 85,95;
            stroke-dashoffset: -93;
        }
    }

    @keyframes rotate {
        0% {transform: rotate(0deg); }
        100% {transform: rotate(360deg); }
    }


</style>