<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M180.001-400v-59.999h280V-400h-280Zm0-160v-59.999h440V-560h-440Zm0-160v-59.999h440V-720h-440Zm344.615 
            539.999v-105.692l217.153-216.153q7.462-7.077 16.111-10.308 8.65-3.23 17.299-3.23 9.436 0 18.252 
            3.538 8.816 3.539 16.029 10.615l37 37.385q6.462 7.461 10 16.153 3.539 8.693 3.539 17.385 0 
            8.692-3.039 17.692-3.038 9-10.5 16.461L630.307-180.001H524.616Zm250.691-211.692 37-38.615-37-37.385-38 
            38 38 38Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>