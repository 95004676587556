<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M292.309-60.002q-30.308 0-51.308-21-21-20.999-21-51.307v-695.382q0-30.308 
            21-51.308t51.308-21h375.382q30.308 0 51.308 21t21 51.308v695.382q0 30.308-21 51.308t-51.308 
            21H292.309ZM280-229.999h400v-500.002H280v500.002Z"/>
        </svg>
        
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>