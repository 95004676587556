<template>
    <div class="user" @click="open_chat()">
        <dp :size="'50px'" :user="props.user"/>
        <div>
            <div class="name">{{ props.user.name }}</div>
            <div class="small">@{{ props.user.username }} 
                {{props.user.bio ? props.user.bio : "Bio Not Set"}}
            </div>
        </div>
    </div>
</template>

<script setup>
import Localbase from 'localbase'
let db = new Localbase('tryraffia')
import { store } from '../../store/store';

import dp from '../util/dp.vue'

import {defineProps, ref} from 'vue'
const props = defineProps(['user'])

const chat = ref({})
const chat_created = ref(false)
const init = () => {
    db.collection('chats').doc({'user_id': props.user.user_id}).get()
    .then(doc => {
        if (doc) {
            chat_created.value = true
            chat.value = doc
        }
    })
}
init()


import { useRouter } from 'vue-router';
const router = useRouter()

const open_chat = () => {
    if (chat_created.value) {
        store.chat = chat.value
        store.chat_user = {...props.user}
        router.push('/chat/' + chat.value.user_id)
    } else {
        let new_chat = {
            'user_id': props.user.user_id,
            'messages': 0,
            'last_message_at': 0,
            'unread': 0,
            'online': false,
            'last_seen': '',
            'updated_at': 0
        }
        db.collection('chats').add(new_chat).then(() => {
            store.chat = new_chat
            store.chat_user = {...props.user}
            store.chats.unshift(new_chat)
            
            router.push('/chat/' + props.user.user_id)
        })
    }
}

</script>

<style scoped>
    .user {
        margin-bottom: 20px;
        padding: 10px;

        display: flex;
        align-items: center;
        gap: 20px;

        transition: .2s;
        border-radius: 5px;
        overflow: hidden;
        /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
    }
    .user:active {
        background-color: rgba(0, 0, 0, 0.1);
    }
    .name {
        font-weight: 700;
    }
    .small {
        font-size: 12px;
        color: gray;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-top: 2px;
    }
</style>