<template>
    <div class="chats">
        
        <div class="head">
            <div class="logo">
                Tryraffia
            </div>

            <div class="icons">
                <div class="icon">
                    <search :size="'20px'"/>
                </div>
                <div class="icon">
                    <optionicon :size="'20px'"/>
                </div>
            </div>
        </div>

        <div class="empty" v-if="chats.length == 0">
            <div class="icon">
                <chaticon size="40px"/>
            </div>
            <div class="text">
                Get started by starting a conversation with your contact
            </div>
        </div>

        <chat_vue v-for="chat in chats" :key="chat.user_id" :chat="chat"/>

        <div class="btn" @click="show_contacts = true">
            <chat_add :size="'20px'" :style="{'height': '20px'}"/>
        </div>

        <contacts v-if="show_contacts" @close="show_contacts = false"/>
    </div>
</template>

<script setup>


import { store } from '../../store/store';


import search from '../../icons/search.vue';
import optionicon from '../../icons/option.vue';

import chaticon from '../../icons/chat.vue';
import chat_vue from '../../components/chat/chat.vue'
import chat_add from "../../icons/chat_add.vue";

import contacts from '../../components/contacts/contacts.vue';

import { ref, computed } from 'vue';

const show_contacts = ref(false)
// const chats = ref([])

// watch(() => store.chats, (new_chats) => {
//         console.log('watch')
//         chats.value = []
//         setTimeout(() => {
//             for (let x of new_chats) {
//                 if (x.messages > 0) {
//                     chats.value.push(x)
//                 }
//             }
//         }, 100);
//     },
//     { immediate: true, deep: true }
// )

const chats = computed(() => {
    return store.chats.filter(chat => {
        return chat.messages > 0
    })
})



</script>

<style scoped>
    .chats {
        padding: 20px;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo {
        font-size: 18px;
        font-weight: 800;
    }
    .icons {
        display: flex;
        gap: 25px;
    }
    .icon {
        fill: #333;
    }

    .empty {
        margin: 80px 0px;
        text-align: center;
        font-size: 12px;
    }
    .icon {
        fill: #333;
    }
    .text {
        margin-top: 5px;
        color: gray;
    }
    .btn {
        position: fixed;
        bottom: 20px;
        right: 20px;

        width: 50px;
        height: 50px;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: var(--main);
        fill: white;

        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
</style>