<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960" >
            <path d="M200-240.003V-200-760-240.003Zm12.309 100.002q-29.923 0-51.115-21.193-21.193-21.192-21.193-51.115v-535.382q0-29.923 
            21.193-51.115 21.192-21.193 51.115-21.193h535.382q29.923 0 51.115 21.193 21.193 21.192 21.193 
            51.115v108.846H760v-108.846q0-5.385-3.462-8.847-3.462-3.462-8.847-3.462H212.309q-5.385 0-8.847 
            3.462-3.462 3.462-3.462 8.847v535.382q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462h535.382q5.385 0 
            8.847-3.462 3.462-3.462 3.462-8.847v-108.846h59.999v108.846q0 29.923-21.193 51.115-21.192 21.193-51.115 
            21.193H212.309Zm320-160q-29.923 0-51.115-21.193-21.193-21.192-21.193-51.115v-215.382q0-29.923 21.193-51.115 
            21.192-21.193 51.115-21.193h255.382q29.923 0 51.115 21.193 21.193 21.192 21.193 51.115v215.382q0 
            29.923-21.193 51.115-21.192 21.193-51.115 21.193H532.309ZM787.691-360q5.385 0 8.847-3.462 3.462-3.462 
            3.462-8.847v-215.382q0-5.385-3.462-8.847-3.462-3.462-8.847-3.462H532.309q-5.385 0-8.847 3.462-3.462 
            3.462-3.462 8.847v215.382q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462h255.382ZM640-420q25 0 
            42.5-17.5T700-480q0-25-17.5-42.5T640-540q-25 0-42.5 17.5T580-480q0 25 17.5 42.5T640-420Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>