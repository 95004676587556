<template>
    <div class="popup">
        
        <swiper class="myswiper"
            :direction="'vertical'"
            :resistanceRatio="0" 
            :slideToClickedSlide="true"
            :slidesPerView="'auto'"
            autoHeight
            
            @swiper="swiper_ready"
            @activeIndexChange="slide_changed"
            >
            
            <swiper-slide>
                <div class="dark">
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="box">
                    <div class="head">Contact Options</div>
                    <div class="main">
                        <div class="option">
                            <div class="icon">
                                <chat :size="'20px'"/>
                            </div>
                            <div>
                                <div class="title">Open Chat</div>
                                <div class="text">Start a conversation with {{ props.user.name }}</div>
                            </div>
                        </div>
                        <div class="option">
                            <div class="icon">
                                <remove_user :size="'20px'"/>
                            </div>
                            <div>
                                <div class="title">Remove Contact</div>
                                <div class="text">Remove {{ props.user.name }} from your contact</div>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>

        </swiper>
    </div>
</template>

<script setup>

import { Swiper, SwiperSlide } from 'swiper/swiper-vue.mjs';
import chat from '../../icons/chat.vue';
import remove_user from '../../icons/remove_user.vue';


import { defineProps, defineEmits } from 'vue';
const props = defineProps(['user'])
const emit = defineEmits(['close'])

console.log(props.user.name)

var swiper_instance = null;
function swiper_ready(s) {
    swiper_instance = s

    setTimeout(() => {
        swiper_instance.slideNext()

    }, 200);
}

function slide_changed(s) {
    
    if (s.activeIndex == 0) {
        setTimeout(() => {
            emit('close')
        }, 200);
    }
}

</script>

<style scoped>
    
    .popup {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;

        width: 100%;

        background-color: rgba(0, 0, 0, 0.9);
        color: white;
    }
    .myswiper {

        height: 100vh;
    }
    .dark {
        height: 100vh;
    }
    .box {
        background-color: white;
        color: #333;
        height: 50vh;
        overflow: auto;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;


    }
    .head {
        padding: 20px;
        font-weight: 700;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .main {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .option {
        padding: 10px 15px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;

        display: flex;
        align-items: center;
        gap: 15px;
        transition: .2s;
    }
    .option:active {
        background-color: rgba(0, 0, 0, 0.1);
        transform: scale(.95);
    }
    .icon {
        fill: #333;
    }
    .title {
        font-weight: 700;
        font-size: 14px;
    }
    .text {
        font-size: 14px;
        color: gray;
        margin-top: 3px;
    }
</style>