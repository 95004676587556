<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M503.846-494.309q25.539-27.769 37.769-63.768 12.231-36 12.231-74.23 0-38.23-12.231-74.23-12.23-36-37.769-63.769 
            52.692 6.077 87.5 45.5 34.807 39.422 34.807 92.499 0 53.076-34.807 92.499-34.808 39.423-87.5 45.499Zm210 
            306.615v-93.845q0-32.709-13.308-62.239-13.307-29.529-37.769-50.683 46 15.308 84.692 41.308 38.692 25.999 
            38.692 71.614v93.845h-72.307Zm72.307-262.307v-80h-80v-59.998h80v-80h59.998v80h80v59.998h-80v80h-59.998Zm-452.306-42.308q-57.749 
            0-98.874-41.124-41.124-41.125-41.124-98.874 0-57.75 41.124-98.874 41.125-41.125 98.874-41.125 57.75 0 98.874 41.125 
            41.125 41.124 41.125 98.874 0 57.749-41.125 98.874-41.124 41.124-98.874 41.124ZM33.849-187.694v-88.922q0-29.384 
            15.961-54.422 15.962-25.038 42.654-38.5 59.307-29.077 119.653-43.615 60.346-14.538 121.73-14.538 61.384 0 121.73 
            14.538 60.346 14.538 119.654 43.615 26.692 13.462 42.653 38.5 15.962 25.038 15.962 54.422v88.922H33.849Z"/>
        </svg>
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>