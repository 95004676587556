<template>
    <div class="splash">
        <div class="main">
            <div>
                <div class="logo">
                    <img src="../../assets/images/logo.png"/>
                </div>
                
                <div class="con">
                    <div class="name">Tryraffia</div>
                </div>
            </div>
        </div>
        
        <div class="slogan">Chat · Transact · Discover</div>
    </div>
</template>

<script setup>
import { Capacitor } from '@capacitor/core';
import { store } from "../../store/store";


import { useRouter, useRoute } from "vue-router";
const router = useRouter()
const route = useRoute()

const init = () => {
    if (process.env.NODE_ENV === "production") {
        setTimeout(() => {
            if (Capacitor.isNativePlatform()) {
                if (route.path == '/') {
                    if (store.registration_complete) {
                        router.push('/home')
                    } else {
                        router.push('/info')
                    }
                }
            }
            else {
                if (store.displayMode != 'standalone') {
                    router.push('/install')
                } else {
                    if (route.path == '/') {
                        if (store.registration_complete) {
                            router.push('/home')
                        } else {
                            router.push('/info')
                        }
                    }
                }
            }
        }, 1500);
    } else {

        setTimeout(() => {
            if (route.path == '/') {
                if (store.registration_complete) {
                    router.push('/home')
                } else {
                    router.push('/info')
                }
            }
        }, 1500);
    }
}
init()

</script>

<style scoped>
    .splash {
        height: 100vh;
        width: 100%;

        display: flex;
        flex-direction: column;
        
        /* color: #0ab63d; */
    }

    .main {
        flex: auto;
        padding: 20px;
        background-color: white;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .logo {
        text-align: center;
    }
    .logo img {
        width: 100px;
        text-align: center;
    }
    .con {
        margin-top: 10px;
        border-radius: 5px;
        /* background-color: var(--trans); */
        /* border-top-left-radius: 20px; */
        /* border-top-right-radius: 20px; */
    }
    .name {
        /* color: var(--main); */
        font-weight: 800;
        text-align: center;
        font-size: 20px;
    }
    .slogan {
        margin-top: 5px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        padding: 20px;
        color: var(--main);
    }
</style>