import { createRouter, createWebHashHistory } from 'vue-router'
import splash from '../views/start/splash.vue'
import install from '../views/start/install.vue'

import info from '../views/start/info.vue'

import login from '../views/start/login.vue'
import complete from '../views/start/complete.vue'

import home from '../views/home.vue'
import chats from '../views/home/chats.vue'
import groups from '../views/home/groups.vue'
import discover from '../views/home/discover.vue'

import stories from '../views/stories/view.vue'
import wallet from '../views/wallet/view.vue'

import profile from '../views/profile/about.vue'
import contacts from '../views/profile/contacts.vue'
import settings from '../views/profile/settings.vue'

import chat from '../views/chat/view.vue'

const routes = [
    {
        path: '/',
        name: 'splash',
        component: splash
    },
    {
        path: '/install',
        name: 'install',
        component: install
    },
    {
        path: '/info',
        name: 'info',
        component: info
    },
    {
        path: '/login',
        name: 'login',
        component: login
    },
    {
        path: '/complete',
        name: 'complete',
        component: complete
    },

    {
        path: '/home',
        name: 'home',
        component: home,
        children: [
            {
                path: '',
                name: 'chats',
                component: chats
            },
            {
                path: '/groups',
                name: 'groups',
                component: groups
            },
            {
                path: '/discover',
                name: 'discover',
                component: discover
            },
            {
                path: '/wallet',
                name: 'wallet',
                component: wallet
            },
            {
                path: '/stories',
                name: 'stories',
                component: stories
            },
            {
                path: '/profile',
                name: 'profile',
                component: profile
            },
            {
                path: '/contacts',
                name: 'contacts',
                component: contacts
            },
            {
                path: '/settings',
                name: 'settings',
                component: settings
            },
            {
                path: '/chat/:id',
                name: 'chat',
                component: chat
            }

        ]
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
