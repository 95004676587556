import { reactive } from 'vue'

export const store = reactive({
    pwa_install_prompt: null,
    displayMode: '',
    
    auth: '',
    app_version: '0.1',
    login: false,
    registration_complete: false,
    user_id: '',
    email: '',
    username: '',
    name: '',
    gender: '',
    picture: '',
    bio: '',
    location: '',
    birthday: '',
    relationship: '',
    phone: '',
    hobbies: '',

    contacts: 0,

    chat: {},
    chat_user: {},
    messages: [],

    status_watch: {},

    chats: [],
    message_watch: {},

    last_message_id: 0,
    last_message_date: 0
})