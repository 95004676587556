<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M172.309-180.001q-30.308 0-51.308-21t-21-51.308v-455.382q0-30.308 
            21-51.308t51.308-21h219.613l80 80h315.769q30.308 0 51.308 21t21 51.308v375.382q0 30.308-21 
            51.308t-51.308 21H172.309Zm281.538-113.846h292.306v-14.307q0-40-39.577-62.846Q667-393.846 
            600-393.846T493.424-371q-39.577 22.846-39.577 62.846v14.307ZM600-446.154q29.154 0 49.576-20.423 
            20.423-20.423 20.423-49.577 0-29.153-20.423-49.576-20.422-20.423-49.576-20.423t-49.576 20.423q-20.423 
            20.423-20.423 49.576 0 29.154 20.423 49.577 20.422 20.423 49.576 20.423Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>