<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M282.308-610.001h315.384v-59.998H282.308v59.998Zm0 160h195.384v-59.998H282.308v59.998Zm407.693 
            280v-120h-120v-59.998h120v-120h59.998v120h120v59.998h-120v120h-59.998Zm-560-14.232v-573.459q0-29.827 
            21.24-51.067t51.067-21.24h475.384q29.827 0 51.067 21.24t21.24 
            51.067v199.538q-8.846-1.231-17.692-1.538-8.846-.308-17.692-.308-98.308 0-166.461 68.429Q480-423.141 
            480-325.385q0 8.846.308 17.692.307 8.846 1.538 17.692H235.769L130.001-184.233Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>