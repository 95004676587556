import { createApp } from 'vue'
import App from './App.vue'


const app = createApp(App)

import router from './router'
app.use(router)


import axios from 'axios'
import VueAxios from 'vue-axios'

const axios_instance = axios.create({
    // baseURL: 'http://localhost:3000'
    baseURL: 'https://api.tryraffia.com/'
});

app.use(VueAxios, axios_instance)
app.provide('axios', app.config.globalProperties.axios)



import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
    // You can set your default options here
};
app.use(Toast, options);


import vue3GoogleLogin from 'vue3-google-login'
import './registerServiceWorker'
app.use(vue3GoogleLogin, {
    clientId: '423653170751-jpu7qfaafap6rltgdq59kros289oe19c.apps.googleusercontent.com'
})

app.mount('#app')
