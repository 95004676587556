<template>
    <div class="home">
        <div class="menu">
            <div class="nav apps">
                <apps :size="'20px'"/>
            </div>
            <router-link to="/profile">
                <div class="nav" :class="[route.name == 'profile' ? 'active_nav' : '']">
                    <div class="dp">
                        <img :src="store.picture"/>
                    </div>
                </div>
            </router-link>
            <router-link to="/home">
                <div class="nav" :class="[route.name == 'chats' ? 'active_nav' : '']">
                    <chat :size="'20px'" v-if="route.name == 'chats'" :style="{'height': '20px'}"/>
                    <chat_unfilled :size="'20px'" v-else :style="{'height': '20px'}"/>
                </div>
            </router-link>
            <router-link to="/groups">
                <div class="nav" :class="[route.name == 'groups' ? 'active_nav' : '']">
                    <group :size="'20px'" v-if="route.name == 'groups'" :style="{'height': '20px'}"/>
                    <group_unfilled :size="'20px'" v-else :style="{'height': '20px'}"/>
                </div>
            </router-link>
            <router-link to="/discover">
                <div class="nav" :class="[route.name == 'discover' ? 'active_nav' : '']">
                    <discover :size="'20px'" v-if="route.name == 'discover'" :style="{'height': '20px'}"/>
                    <discover_unfilled :size="'20px'" v-else :style="{'height': '20px'}"/>
                </div>
            </router-link>
            <router-link to="/stories">
                <div class="nav" :class="[route.name == 'stories' ? 'active_nav' : '']">
                    <stories :size="'20px'" v-if="route.name == 'stories'" :style="{'height': '20px'}"/>
                    <stories_unfilled :size="'20px'" v-else :style="{'height': '20px'}"/>
                </div>
            </router-link>
            <router-link to="/wallet">
                <div class="nav" :class="[route.name == 'wallet' ? 'active_nav' : '']">
                    <wallet :size="'20px'" v-if="route.name == 'wallet'" :style="{'height': '20px'}"/>
                    <wallet_unfilled :size="'20px'" v-else :style="{'height': '20px'}"/>
                </div>
            </router-link>
            <router-link to="/contacts">
                <div class="nav" :class="[route.name == 'contacts' ? 'active_nav' : '']">
                    <contacts :size="'20px'" v-if="route.name == 'contacts'" :style="{'height': '20px'}"/>
                    <contacts_unfilled :size="'20px'" v-else :style="{'height': '20px'}"/>
                </div>
            </router-link>
            <router-link to="/settings">
                <div class="nav" :class="[route.name == 'settings' ? 'active_nav' : '']">
                    <settings :size="'20px'" v-if="route.name == 'settings'" :style="{'height': '20px'}"/>
                    <settings_unfilled :size="'20px'" v-else :style="{'height': '20px'}"/>
                </div>
            </router-link>

            <div class="logout" @click="logout_func()">
                <logout :size="'20px'"/>
            </div>
        </div>
        <div class="main">
            <router-view></router-view>
        </div>
    </div>
</template>

<script setup>

import Localbase from 'localbase'
let db = new Localbase('tryraffia')

import { store } from '../store/store.js'


import apps from '../icons/apps.vue'
import chat from '../icons/chat.vue'
import chat_unfilled from '../icons/unfilled/chat.vue'
import group from '../icons/group.vue'
import group_unfilled from '../icons/unfilled/group.vue'
import discover from '../icons/discover.vue'
import discover_unfilled from '../icons/unfilled/discover.vue'
import stories from '../icons/stories.vue'
import stories_unfilled from '../icons/unfilled/stories.vue'
import wallet from '../icons/wallet.vue'
import wallet_unfilled from '../icons/unfilled/wallet.vue'
import contacts from '../icons/contacts.vue'
import contacts_unfilled from '../icons/unfilled/contacts.vue'
import settings from '../icons/settings.vue'
import settings_unfilled from '../icons/unfilled/settings.vue'

import logout from '../icons/logout.vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const logout_func = () => {
    db.delete().then(() => {
        localStorage.clear()
        router.push('/')
        router.go()
    })
}

</script>

<style scoped>

    .home {
        position: relative;
        height: 100vh;
        width: 100vw;


        display: grid;
        grid-template-columns: max-content auto;
    }

    .menu {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 20px 0px;
        /* padding-right: 0px; */
        background-color: #edeef0;
    }

    .nav {
        padding: 7px 10px;
    }
    .apps {
        padding: 0px 10px;
    }

    .active_nav {
        fill: white;
        background-color: var(--main);
    }

    .logout {
        margin-top: auto;
        text-align: center;
    }
    

    .dp {
        background-color: white;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .dp img {
        width: 20px;
    }

    .main {
        height: 100%;
        overflow: auto;
    }

</style>