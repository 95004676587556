<template>
    <div class="install">
        <div class="main">
            
            <div class="logo">
                <img src="../../assets/images/logo.png"/>
            </div>
            
            <div class="name">Tryraffia</div>
            <div class="slogan">Chat · Transact · Discover</div>
        </div>
        <div class="bottom">
            <div v-if="installing">
                
                <div class="info" v-if="is_ios">
                    <div class="info_title">Installing App</div>
                    <div class="info_text">
                        To complete app installation, click the browser share button then click add to home screen
                    </div>
                </div>
                <div class="info" v-else>
                    <div class="info_title">Installing App</div>
                    <div class="info_text">
                        Tryraffia app is been installed, you can launch from home screen after installation
                    </div>
                </div>
            </div>
            <div class="btn" v-else @click="install_pwa()">Install App</div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from "vue";
    import { store } from "../../store/store";
    
    import { useToast } from "vue-toastification";
    const toast = useToast()

    
    const installing = ref(false)
    const is_ios = ref(false)

    const install_pwa = async () => {

        installing.value = true

        let check_for_ios = [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform) 
        
        if (check_for_ios) {
            is_ios.value = true
        } else {
            if (store.pwa_install_prompt == null) {
                installing.value = false
            }
            store.pwa_install_prompt.prompt()
            const { outcome } = await store.pwa_install_prompt.userChoice;
            
            
            if (outcome === 'accepted') {
                toast.success('Launch Tryraffia app from your home screen after installation')
                store.pwa_install_prompt = null;
                
            } else if (outcome === 'dismissed') {
                
                toast.error('To use Tryraffia app, you must install the PWA app')
                installing.value = false
            }
        }
    }
</script>

<style scoped>
    
    .install {
        height: 100vh;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
    }
    .main {
        flex: auto;
        padding: 20px;
        background-color: white;

    }
    .logo {
        text-align: center;
    }
    .logo img {
        width: 100px;
        text-align: center;
    }
    .name {
        /* color: var(--main); */
        font-weight: 800;
        text-align: center;
        font-size: 20px;
    }
    .slogan {
        margin-top: 5px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        padding: 20px;
        color: var(--main);
    }

    .bottom {
        position: absolute;
        bottom: 0px;
        left: 0px;
        padding: 20px;
        width: 100%;
        box-sizing: border-box;
    }
    .btn {
        background-color: var(--main);
        color: white;
        padding: 15px;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
    }

    
    .info_title {
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 5px;
    }
    .info_text {
        font-size: 14px;
        color: gray;
        line-height: 22px;
    }
</style>