<template>
    <div class="view">
        <div class="head">
            <div class="left">
                <div class="back" @click="go_back()">
                    <back size="20px" :style="{'height': '20px'}"/>
                </div>
                <dp size="40px" :user="user"/>
                <div class="info">
                    <div class="name">
                        {{ user.name }}
                    </div>
                    <div class="status">
                        <div v-if="user_status.online" class="online">
                            Online
                        </div>
                        <div v-else>
                            <div v-if="user_status.last_seen == ''">
                                ...
                            </div>
                            <div v-else>
                                {{ moment(user_status.last_seen).calendar() }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="options">
                <div class="icon">
                    <videoVue size="20px" :style="{'height': '20px'}"/>
                </div>
                <div class="icon">
                    <call size="20px" :style="{'height': '20px'}"/>
                </div>
            </div>
        </div>
        <div class="main">
            <message_vue v-for="message in store.messages" :key="message.message_id" :message="message"/>
        </div>
        <div class="bottom">
            <send :chat="chat" @new_message="new_message($event)"/>
        </div>
    </div>
</template>

<script setup>
import moment from 'moment'

import Localbase from 'localbase'
let db = new Localbase('tryraffia')
import { store } from '../../store/store';
import { socket } from '../../socket'

import back from '../../icons/back.vue'
import dp from '../../components/util/dp.vue'

import videoVue from '../../icons/video.vue';
import call from '../../icons/call.vue';

import message_vue from '../../components/chat/message.vue'
import send from '../../components/chat/send.vue'


import { ref,computed, onUnmounted } from 'vue';
const user = ref({})
const chat = ref({})

import { useRoute, useRouter } from 'vue-router';
const router = useRouter()
const route = useRoute()


const continue_init = () => {

    user.value = store.chat_user
    chat.value = store.chat

    db.collection(chat.value.user_id).orderBy('time', 'desc').limit(1000).get()
    .then(doc => {
        store.messages = doc
    })

    store.status_watch[user.value.user_id] = {
        "online": false,
        "last_seen": ""
    }

    socket.emit('watch_user_status', {
        'auth': store.auth,
        'watching': user.value.user_id
    })
}

const init = () => {
    
    if (Object.keys(store.chat).length == 0) { 
        
        db.collection('contacts').doc({'user_id': route.params.id}).get().then(doc => {
            store.chat_user = doc
            user.value = doc
            
            db.collection('chats').doc({'user_id': route.params.id}).get()
            .then(doc => {
                if (doc) {
                    store.chat = doc
                    chat.value = doc

                    continue_init()
                }
            })
        })
    } else {
        continue_init()
    }

}
init()


const user_status = computed(() => {
    return store.status_watch[user.value.user_id]
})


const go_back = () => {
    router.go(-1)
}

const new_message = (e) => {
    store.messages.unshift(e)
}

onUnmounted(() => {
    store.messages = []
    store.chat = {}
    store.chat_user = {}

    delete store.status_watch[user.value.user_id]
    
    socket.emit('un_watch_user_status', {
        'auth': store.auth,
        'user_to_un_watch': user.value.user_id
    })
})

</script>

<style scoped>
    .view {
        display: flex;
        flex-direction: column;
        height: 100vh;
    
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* gap: 20px; */
        padding: 0px 20px;
        padding-left: 10px;
        padding-right: 0px;
        

        /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
    }
    .left {
        display: flex;
        align-items: center;
    }
    .back {
        width: 40px;
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .info {
        margin-left: 15px;
    }
    .name {
        font-size: 14px;
        font-weight: 700;
    }
    .status {
        margin-top: 2px;
        font-size: 12px;
        font-weight: 500;
    }
    .online {
        color: var(--main);
    }

    .options {
        display: flex;
        align-items: center;
        gap: 20px;

        margin-left: auto;
        background-color: var(--main);
        padding: 15px 20px;

        position: relative;
        border-bottom-left-radius: 20px;
    }
    .options:after {
        content: "";
        position: absolute;
        left: -20px;
        top: 0px;

        width: 20px;
        height: 20px;
        background-color: transparent;
        border-top-right-radius: 50px;
        box-shadow: 5px -4px 0px var(--main), 8px -4px 0px var(--main);
    }
    .options:before {
        content: "";
        position: absolute;
        right: 0px;
        bottom: -20px;

        width: 20px;
        height: 20px;
        background-color: transparent;
        border-top-right-radius: 50px;
        box-shadow: 5px -4px 0px var(--main), 8px -4px 0px var(--main);
    }
    .icon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 35px;
        height: 35px;
        /* background-color: var(--main2); */
        /* border: 1px solid #fff; */
        fill: white;
        border-radius: 50px;
    }
    
    .main {
        height: 100%;
        flex: auto;
        overflow: auto;

        display: flex;
        flex-direction: column-reverse;
    }

</style>