<template>
    <div class="discover">
        
        <div class="head">
            <div class="logo">
                Discover
            </div>

            <div class="icons">
                <div class="icon">
                    <search :size="'20px'"/>
                </div>
            </div>
        </div>

        <div class="box">
            <div>
                <div class="big">Discover</div>
                <div class="text">Meet others who are using tryraffia to communicate and transact</div>
            </div>
            <div class="">
                <div class="illu">
                    <discover :size="'70px'"/>
                </div>
            </div>
        </div>
        <div>
            <div class="section">
                <div class="title">Discover Users</div>
                <div class="icon">
                    <info :size="'20px'"/>
                </div>
            </div>

            <spinner class="l" v-if="loading"/>
            <div v-else>
                <user_vue v-for="user in users" :key="user.user_id" :user="user"/>
            </div>
        </div>
        <div>
            <div class="section">
                <div class="title">Public Groups</div>
                <div class="icon">
                    <info :size="'20px'"/>
                </div>
            </div>

            <spinner class="l"/>
        </div>
    </div>
</template>

<script setup>
import search from '../../icons/search.vue';
import discover from "../../icons/discover.vue";
import info from "../../icons/unfilled/info.vue";

import spinner from "../../components/util/loading.vue";
import user_vue from '../../components/user/user.vue'

import { inject, ref } from "vue";

const axios = inject('axios')

const users = ref([])
const loading = ref(true)

const init = () => {
    axios.post('/user/discover')
    .then(data => {
        let r = data.data

        if (r.success) {
            users.value = r.users
            loading.value = false
        }
    })
}
init()

</script>

<style scoped>
    .discover {
        padding: 20px;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo {
        font-size: 18px;
        font-weight: 800;
    }
    .icons {
        display: flex;
        gap: 25px;
    }
    .icon {
        fill: #333;
    }


    .box {
        background-color: #09B83E;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%23009419' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%23008717' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%2338b600' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%23319306' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%23a9d800' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%237e9d0e' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%23fbba00' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%23a68217' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%23ff5a1e' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%23ae4721' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%23ff4068' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%23b42d49' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
        /* background-attachment: fixed; */
        background-size: contain;
        background-position: bottom right;
        background-repeat: no-repeat;
        border-radius: 5px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        margin-top: 20px;
        padding: 20px;
        color: white;
    }

    .big {
        font-size: 25px;
        font-weight: 800;
    }
    .text {
        margin-top: 5px;
        font-size: 12px;
        line-height: 16px;
    }

    .illu {
        fill: white;
    }

    .section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }
    .title {
        font-weight: 700;
    }

    .l {
        padding: 50px 0px;
    }
</style>