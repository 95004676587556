<template>
    <div class="message" :class="[message_class]">
        <div class="date" v-if="my_message">
            {{ message_date }}
        </div>
        <div class="box" :class="class_name">
            {{ message.text }}
        </div>
        <div class="date" v-if="!my_message">
            {{ message_date }}
        </div>
    </div>
</template>

<script setup>
import moment from 'moment';

import { store } from '../../store/store';
import { socket } from '../../socket';

import { defineProps, ref, watch } from 'vue'

const props = defineProps(['message'])

const message = ref({})
const my_message = ref(false)
const message_class = ref('')
const message_date = ref('')

const init = () => {
    message.value = props.message
    my_message.value = props.message.sender == store.user_id

    message_class.value = my_message.value ? 'right' : 'left'
    
    message_date.value = moment(message.value.date).calendar({
        sameDay: 'HH:mm',
        lastDay: 'ddd HH:mm',
        lastWeek: 'ddd HH:mm',
        sameElse: 'DD/MM/YYYY HH:mm'
    })

    if (!message.value.seen) {

        if (message.value.sender != store.user_id) {
        
            socket.emit('message_update', {
                'auth': store.auth,
                'update_type': 'seen',
                'message_id': message.value.message_id,
                'text': message.value.text,
                'meta': message.value.meta
            })
        }
    }

}
init()

const class_name = ref('')
const compute_class = () => {
    if (props.message.sender == store.user_id) {
        if (message.value.delivered) {
            class_name.value = 'delivered'
        }
        if (message.value.seen) {
            class_name.value = 'seen'
        }
    }
}
compute_class()

watch(props.message, () => {
    message.value = {...props.message}
    compute_class()
})


</script>

<style scoped>
    .message {
        margin-top: 20px;
        display: flex;
        gap: 10px;
        align-items: center;
    }
    .date {
        font-size: 12px;
        color: gray;
    }
    .box {
        padding: 10px;
        color: black;
        font-size: 14px;
        line-height: 22px;
        max-width: 80%;

        background-color: rgba(0, 0, 0, 0.1);
    }
    .right {
        justify-content: flex-end;
    }
    .right .box {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-right: 20px;
        
    }
    .left .box {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding-left: 20px;
    }
    .delivered {
        background-color: var(--trans);
    }
    .seen {
        background-color: var(--main);
        color: white;
    }
</style>