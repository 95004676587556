<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M212.309-140.001q-30.308 0-51.308-21t-21-51.308v-535.382q0-30.308 21-51.308t51.308-21h268.076V-760H212.309q-4.616 
            0-8.463 3.846-3.846 3.847-3.846 8.463v535.382q0 4.616 3.846 8.463 3.847 3.846 8.463 
            3.846h268.076v59.999H212.309Zm436.922-169.232-41.537-43.383 97.384-97.385H363.846v-59.998h341.232l-97.384-97.385 
            41.537-43.383L819.999-480 649.231-309.233Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>