<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M706.922-334.231q0 97.439-67.807 165.834-67.808 68.396-165.076 68.396-97.269 
            0-165.269-68.396-67.999-68.395-67.999-165.834v-359.615q0-69.23 48.076-117.691 48.077-48.462 
            117.307-48.462 69.231 0 117.307 48.462 48.077 48.461 48.077 117.691v340.384q0 40.615-28.292 
            69.346-28.291 28.73-68.961 28.73-40.669 0-69.399-28.531-28.731-28.531-28.731-69.545v-351.152h59.999v351.152q0 
            16.077 10.807 27.078 10.808 11 26.885 11 16.078 0 26.885-11 10.808-11.001 
            10.808-27.078V-694.23q-.615-44.308-30.776-75.039T406.154-800q-44.261 0-74.823 
            30.923-30.562 30.923-30.562 75.231v359.615q-.615 72.538 50.154 123.385Q401.693-160 
            474.178-160q71.465 0 121.49-50.846 50.025-50.847 51.256-123.385v-370.383h59.998v370.383Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>