<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960" >
            <path d="M440-570.001q29.154 0 49.576-20.423 20.423-20.422 20.423-49.576t-20.423-49.576Q469.154-709.999 
            440-709.999t-49.576 20.423Q370.001-669.154 370.001-640t20.423 49.576q20.422 20.423 49.576 20.423Zm.385 160q39.23 
            0 74.499-15.73 35.269-15.731 62.346-44.961-33.077-19.538-67.538-29.423-34.462-9.884-69.307-9.884-38.308 0-73.731 
            10.077-35.423 10.076-63.499 29.23 26.692 29.23 62.153 44.961 35.462 15.73 75.077 15.73Zm395.154 
            288.078L636.385-321.078q-42.539 33.923-90.693 52.5Q497.539-250.001 440-250.001q-129.769 0-219.884-90.115Q130.001-430.231 
            130.001-560t90.115-219.884Q310.231-869.999 440-869.999t219.884 90.115Q749.999-689.769 749.999-560q0 57.539-18.577 
            105.885-18.577 48.346-52.5 90.884l198.77 198.77-42.153 42.538ZM440-309.999q103.846 0 176.924-73.077Q690.001-456.154 
            690.001-560t-73.077-176.924Q543.846-810.001 440-810.001t-176.924 73.077Q189.999-663.846 189.999-560t73.077 
            176.924Q336.154-309.999 440-309.999ZM440-560Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>