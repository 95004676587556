<template>
    <div class="contacts">
        
        <div class="head">
            <div class="logo">
                Contacts
            </div>

            <div class="icons">
                <div class="icon">
                    <search :size="'20px'"/>
                </div>
            </div>
        </div>

        <div class="empty" v-if="contacts.length == 0">
            <div class="icon">
                <contacts_icon :size="'40px'"/>
            </div>
            <div class="text">Your contact list is currently empty</div>
        </div>

        <user_vue v-for="user in contacts" :key="user.user_id" :user="user"/>

        
        <div class="btn">
            <add_user :size="'20px'" :style="{'height': '20px'}"/>
        </div>
    </div>
</template>

<script setup>

import Localbase from 'localbase'
let db = new Localbase('tryraffia')


import search from '../../icons/search.vue';

import contacts_icon from '../../icons/contacts.vue';
import add_user from '../../icons/add_user.vue';

import user_vue from '../../components/user/user.vue'


import { ref } from 'vue';

const contacts = ref([])

const init = () => {
    db.collection('contacts').get().then(doc => {
        contacts.value = doc
    })
}
init()

</script>

<style scoped>


.contacts {
    padding: 20px;
}
.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo {
    font-size: 18px;
    font-weight: 800;
}
.icons {
    display: flex;
    gap: 25px;
}
.icon {
    fill: #333;
}



.empty {
    margin: 80px 0px;
    text-align: center;
    font-size: 12px;
}
.icon {
    fill: #333;
}
.text {
    margin-top: 5px;
    color: gray;
}

.btn {
    position: fixed;
    bottom: 20px;
    right: 20px;

    width: 50px;
    height: 50px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--main);
    fill: white;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
</style>