<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M480-420.001q-41.922 0-70.961-29.038-29.038-29.039-29.038-70.961v-240q0-41.922 29.038-70.961 
            29.039-29.038 70.961-29.038t70.961 29.038q29.038 29.039 29.038 70.961v240q0 41.922-29.038 70.961-29.039 
            29.038-70.961 29.038Zm-29.999 290v-131.847q-99-11.307-164.5-84.922T220.001-520H280q0 83 58.5 141.5T480-320q83 
            0 141.5-58.5T680-520h59.999q0 99.615-65.5 173.23t-164.5 84.922v131.847h-59.998Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>