<template>
    <div class="send_container">
        <div class="input">
            <div class="input_icon mic">
                <mic size="20px" :style="{'height': '20px'}"/>
            </div>
            <textarea
                ref="textarea"
                v-model="input"
                class="resize-none"
                placeholder="Message..." 
                rows="1"
            />

            <div class="input_icon">
                <attachment size="20px" :style="{'height': '20px'}"/>
            </div>
        </div>
        <div class="send" @click="send_nessage()">
            <send size="20px" :style="{'height': '20px'}"/>
        </div>
    </div>
</template>

<script setup>

import Localbase from 'localbase'
let db = new Localbase('tryraffia')

import { socket} from '../../socket'
import { store } from '../../store/store';
    
import mic from '../../icons/mic.vue';
import attachment from '../../icons/attachment.vue';
import send from '../../icons/send.vue';

import { useTextareaAutosize } from '@vueuse/core'
const { textarea, input } = useTextareaAutosize()

import { v4 as uuidv4 } from 'uuid'

import {defineProps, defineEmits} from 'vue'

const props = defineProps(['chat'])
const emit = defineEmits(['new_message'])

const send_nessage = () => {
    
    let message_id = uuidv4()

    let new_message = {
        'type': 'text',
        'text': input.value,
        'date': new Date,
        'time': (new Date).getTime(),
        'message_id': message_id,
        'sender': store.user_id,
        'receiver': props.chat.user_id,
        'sent': false,
        'delivered': false,
        'delivered_at': '',
        'seen': false,
        'seen_at': '',
        'meta': {}
    }

    emit('new_message', new_message)


    db.collection('unresolve_messages').add(new_message)
    .then(() => {
        db.collection(new_message.receiver).add(new_message)
        .then(() => {
            
            socket.emit('new_message', {
                'auth': store.auth,
                'message_id': message_id,
                'type': new_message.type,
                'text': new_message.text,
                'receiver': new_message.receiver,
                'meta': new_message.meta
            })

            input.value = ''
        })
    })

    console.log(new_message);

}


</script>

<style scoped>
    
    .send_container {
        display: flex;
        align-items: flex-end;
        gap: 20px;
        padding: 20px;
    }
    .input {
        display: flex;
        align-items: flex-end;
        flex: auto;
        border-radius: 5px;
        overflow: hidden;
        
        background-color: var(--trans);
    }
    textarea {
        border: none;
        outline: none;
        background-color: transparent;
        flex: auto;
        padding: 15px 0px;
        box-sizing: border-box;
        padding-left: 0px;
        color: black;

        min-height: 40px;
        max-height: 120px;

        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    textarea::placeholder {
        color: black;
    }

    textarea::-webkit-scrollbar {
        display: none;
    }

    .input_icon {
        height: 47px;
        width: 40px;

        display: flex;
        align-items: center;
        justify-content: center;

        fill: var(--main);

        transition: background-color .2s;
    }
    .input_icon:active {
        background-color: var(--main);
        fill: white;
    }

    .send {
        background-color: var(--main);
        fill: white;

        height: 47px;
        width: 47px;
        border-radius: 5px;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: .2s;
    }
    .send:active {
        transform: scale(.9);
    }
</style>