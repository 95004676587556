<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960" >
            <path d="M284.616-275.387q-15.039 0-25.212-10.173t-10.173-25.211v-59.999H744.23l12.693 12.693V-720h59.998q15.039 0 
            25.212 10.173t10.173 25.211v550.766L710.769-275.387H284.616Zm-176.922-33.846v-515.381q0-15.039 
            10.173-25.212t25.212-10.173h498.46q15.038 0 25.211 10.173 10.173 10.173 10.173 25.212v338.46q0 15.038-10.173 
            25.211-10.173 10.173-25.211 
            10.173H249.231L107.694-309.233Zm509.23-201.536V-800H167.693v316.926l27.694-27.695h421.537Zm-449.231 0V-800v289.231Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>