<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M480.067-100.001q-78.836 
            0-148.204-29.857-69.369-29.856-120.682-81.039-51.314-51.182-81.247-120.374-29.933-69.191-29.933-147.96 
            0-39.587 7.847-77.793 7.846-38.206 22.922-74.513l46.615 46.614q-8.385 26-12.885 52.137T160-480q0 
            134 93 227t227 93q134 0 227-93t93-227q0-134-93-227t-227-93q-27 0-52.885 4.5-25.884 4.5-51.269 
            12.885l-45.999-45.999q35.769-15.308 72.115-23.346 36.346-8.039 75.73-8.039 79.236 0 148.925 
            29.77 69.69 29.769 121.382 81.268 51.692 51.5 81.846 120.81 30.154 69.311 30.154 148.115 0 
            78.805-29.92 148.173-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 
            29.933ZM228.462-683.846q-19.615 
            0-33.653-14.039-14.038-14.038-14.038-33.653t14.038-33.653q14.038-14.038 33.653-14.038t33.653 
            14.038q14.039 14.038 14.039 33.653t-14.039 33.653q-14.038 14.039-33.653 14.039ZM240-480q0-100 
            70-170t170-70q100 0 170 70t70 170q0 100-70 170t-170 70q-100 0-170-70t-70-170Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>