<template>
    <div class="info">

        <div class="logo">
            <img src="../../assets/images/logo-text.png"/>
        </div>
        
        <div class="main">
            <swiper
                :modules="modules"
                :slides-per-view="1"
                :space-between="0"
                @swiper="onSwiper"
                @slideChange="onSlideChange" >

                <swiper-slide>
                    <div class="slide">
                        <div>
                            <div class="image">
                                <img src="../../assets/images/logo.png"/>
                            </div>
                            <div class="title">Welcome to Tryraffia</div>
                            <div class="text">
                                Let's show you how crypto and blockchain will improve your conversations!
                                Get ready to use Tryraffia. 
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>

        <div class="bottom">
            <router-link to="/login">
                <div class="btn">Get Started</div>
            </router-link>
        </div>

    </div>
</template>

<script>

import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/swiper-vue.mjs';


export default {

    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log('slide change');
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Pagination],
        };
    }
}
</script>

<style scoped>
    .info {
        position: relative;
        height: 100vh;
        width: 100vw;


        display: flex;
        flex-direction: column;

    }
    .logo {
        padding: 20px;
        background-color: white;
    }
    .logo img {
        width: 70px;
    }

    .main {
        flex: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
    }
    .slide {
        height: fit-content;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        box-sizing: border-box;
    }
    .image {
        text-align: center;
    }
    .image img {
        width: 100px;
        text-align: center;
    }
    .title {
        font-size: 20px;
        font-weight: 800;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .text {
        text-align: center;
        opacity: .8;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 10px;
    }

    .bottom {
        width: 100%;
        padding: 30px 20px;
        box-sizing: border-box;
        
        background-color: var(--bg);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .btn {
        background-color: white;
        border-radius: 5px;
        width: 100%;
        padding: 15px;
        font-weight: 600;
        font-size: 14px;
        box-sizing: border-box;
        color: #001122;
        /* color: var(--main); */
        text-align: center;
        /* cursor: pointer; */

        position: relative;
        z-index: 3;
        transition: .3s;
    }
    .btn:active {
        transform: scale(.95);
    }
</style>