<template>
    <div :style="{'--main': c.main, '--main2': c.main2, '--trans': c.trans, '--bg': c.bg, '--dark': c.dark}">
        <router-view v-if="ready"/>
    </div>
</template>

<script setup>
import 'swiper/swiper-bundle.min.css';

import {state, socket} from './socket'

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";


import Localbase from 'localbase'
let db = new Localbase('tryraffia')
let db1 = new Localbase('tryraffia')
let db2 = new Localbase('tryraffia')
let db3 = new Localbase('tryraffia')
let db4 = new Localbase('tryraffia')

import { store } from './store/store.js'


import { ref, inject } from 'vue'
import { useRouter } from 'vue-router';


import { useToast } from "vue-toastification";
const toast = useToast()


const router = useRouter()
const axios = inject('axios')


const ready = ref(false)
const c = ref({
    main: 'rgb(10, 182, 61)',
    trans: 'rgba(10, 182, 61, 0.2)',
    main2: 'rgb(4, 76, 26)',
    bg: '#09B83E',
    dark: '#000c20'
})


const init = async () => {
    document.title = 'Tryraffia'

    if (!state.connected) {
        socket.connect()
    }

    

    window.addEventListener('beforeinstallprompt', (e) => {
        console.log('before install');

        e.preventDefault();
        
        store.pwa_install_prompt = e;
        
    });
    
    window.addEventListener('DOMContentLoaded', () => {
        let displayMode = 'browser tab';
        if (window.matchMedia('(display-mode: standalone)').matches) {
            displayMode = 'standalone';
        }
        store.displayMode = displayMode
    });


    const firebaseConfig = {
        apiKey: "AIzaSyD0-QgsyYpI6ZD1EXXhKHUFUP3xR1zMO10",
        authDomain: "tryraffia-b051b.firebaseapp.com",
        projectId: "tryraffia-b051b",
        storageBucket: "tryraffia-b051b.appspot.com",
        messagingSenderId: "556653169052",
        appId: "1:556653169052:web:488fc99be46b7c27cee059",
        measurementId: "G-BKQ6JPJ0WS"
    };

        
    initializeApp(firebaseConfig);

    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        // ...
    });


    let auth = await db.collection('auth').doc({'id': 1}).get()
    console.log('aaaaaa', auth);


    db.collection('auth').doc({'id': 1}).get().then(doc => {
        if (doc) {
            
            getToken(messaging, { vapidKey: 'BNEPEHMMH8daydd2jpIIFO9fxD-NstyULmCkZIfl1chUW_Nb_UPbD5X32YFGWPo1Sj8tDGE37xmT5SvrBSjUOVI' })
            .then((currentToken) => {
                if (currentToken) {
                    authenticate_socket(doc.user_id, doc.session, currentToken, doc.last_message_id)
                }
                else {
                    authenticate_socket(doc.user_id, doc.session, '', doc.last_message_id)
                    toast.error('Allow notifications')
                }
            }).catch(() => {
                authenticate_socket(doc.user_id, doc.session, '', doc.last_message_id)
                toast.error('Allow Tryraffia to send you notifications')
            });

            store.auth = doc.session
            axios.defaults.headers.common['Auth'] = doc.session
            
            if (doc.registration_complete) {

                db.collection('chats').get()
                .then(doc => {
                    store.chats = doc

                    db.collection('unresolve_messages').get()
                    .then(doc => {
                        for (let x of doc) {
                            
                            socket.emit('new_message', {
                                'auth': store.auth,
                                'message_id': x.message_id,
                                'type': x.type,
                                'text': x.text,
                                'receiver': x.receiver,
                                'meta': x.meta
                            })
                        }
                    })
                })
                // router.push('/home')

                store.login = true
                store.registration_complete = true
                store.user_id = doc.user_id
                store.email = doc.email
                store.username = doc.username
                store.name = doc.name
                store.gender = doc.gender
                store.picture = doc.picture
                
                store.bio = doc.bio,
                store.location = doc.location,
                store.birthday = doc.location,
                store.relationship = doc.relationship,
                store.phone = doc.phone,
                store.hobbies = doc.hobbies
                
                store.last_message_id = doc.last_message_id
                store.last_message_date = doc.last_message_date

            } else {
                router.push('/complete')

                store.user_id = doc.user_id
                store.email = doc.email
                store.picture = doc.picture
            }

        } else {
            router.push('/')
        }
        setTimeout(() => {
            ready.value = true
        }, 200);
    })
}
init()

const authenticate_socket = (user_id, auth, notification_id, last_message_id) => {
    socket.emit('authenticate', {
        'user_id': user_id,
        'Auth': auth,
        'notification_id': notification_id,
        'app_version': store.app_version,
        'last_message_id': last_message_id
    })
}


socket.on('user_status', (data) => {
    console.log(data);
    store.status_watch[data.user_id] = data

    db.collection('chats').doc({'user_id': data.user_id}).update({
        'last_seen': data.last_seen,
        'online': data.online
    })
})

var new_messages_array = []
const message_evaluation_running = ref(false)

socket.on('new_message', async (message) => {

    new_messages_array.push(message)

    if (!message_evaluation_running.value) {
        message_evaluation_running.value = true

        for (let x of new_messages_array) {
            
            await evaluate_new_message(x)

            new_messages_array.shift()
        }
        
        message_evaluation_running.value = false
    }
    
})


async function evaluate_new_message(message) {
    console.log('mmvmm', message);

    if (message.time >= store.last_message_date) {
        await db1.collection('auth').doc({'id': 1}).update({
            last_message_id: message.id,
            last_message_date: message.time
        })

        store.last_message_id = message.id
        store.last_message_date = message.time
    }

    let chat_id = message.sender
    if (message.sender == store.user_id) {
        chat_id = message.receiver

        let is_unresolve = await db2.collection('unresolve_messages').doc({'message_id':message.message_id }).get()
        
        if (is_unresolve) {
            await db2.collection('unresolve_messages').doc({'message_id':message.message_id }).delete()
        } else {
            await db3.collection(chat_id).add(message)
            // db2.collection('unresolve_messages').add(message)
        }

    }
    else {
        await db3.collection(chat_id).add(message)

        if ('user_id' in store.chat) {
            if (store.chat.user_id == chat_id) {
                store.messages.unshift(message)
            }
        }

        //broadcast delivered
        
        socket.emit('message_update', {
            'auth': store.auth,
            'update_type': 'delivered',
            'message_id': message.message_id,
            'text': message.text,
            'meta': message.meta
        })
    }

    let chat_doc = await db.collection('chats').doc({'user_id': chat_id}).get()

    if (chat_doc) {
        await db.collection('chats').doc({'user_id': chat_id}).update({
            'messages': chat_doc.messages + 1,
            'unread': chat_doc.unread,
            'last_message_at': message.time
        })
        let chat_index = -1
        
        let i = 0
        let found = false
        for (let x of store.chats) {

            if (x.user_id == chat_id) {
                found = true
                break
            }
            i++
        }
        if (found) {
            chat_index = i
        }


        if (chat_index > -1) {
            store.chats[chat_index]['last_message_at'] = message.time
            store.chats[chat_index]['messages'] = chat_doc.messages + 1
            store.chats[chat_index]['updated_at'] = (new Date).getTime()

            store.chats.sort((a, b) => {
                return b.last_message_at - a.last_message_at
            })
        }
    } else {

        let new_chat = {
            'user_id': chat_id,
            'messages': 1,
            'last_message_at': message.time,
            'unread': 1,
            'online': false,
            'last_seen': ''
        }

        let is_contact = await db.collection('contacts').doc({'user_id': chat_id}).get()
        
        if (is_contact) {
            await db.collection('chats').add(new_chat)
            
            store.chats.unshift(new_chat)
        } 
        else {
            axios.post('/user/add_to_contact', {
                'user_id': chat_id,
                'is_real': false
            })
            let res_data = await axios.post('/user/user', {
                'user_id': chat_id
            })

            let r = res_data.data
            if (r.success) {
                r.user['real'] = false
                await db.collection('contacts').add(r.user)
                await db.collection('chats').add(new_chat)

                store.chats.unshift(new_chat)
            }
        }
    }
        
}


socket.on('message_update', (new_update) => {

    console.log(new_update);
    
    let chat_id = new_update.sender
    if (new_update.sender == store.user_id) {
        chat_id = new_update.receiver
    }

    db4.collection(chat_id).doc({'message_id': new_update.message_id}).update(new_update)
    .then(() => {
        
        let chat_index = -1
        let i = 0
        let found = false
        for (let x of store.chats) {

            if (x.user_id == chat_id) {
                found = true
                break
            }
            i++
        }
        if (found) {
            chat_index = i
        }
        console.log('chat_index', chat_index);

        if (chat_index > -1) {
            store.chats[chat_index]['updated_at'] = (new Date).getTime()
        }
    })
    
    if (Object.keys(store.chat).length > 0) {
        if (store.chat.user_id == chat_id) {
            
            let message_index = -1
            let i = 0
            let found = false
            for (let x of store.messages) {

                if (x.message_id == new_update.message_id) {
                    found = true
                    break
                }
                i++
            }
            if (found) {
                message_index = i
            }

            console.log('message_index', message_index);
            
            if (message_index > -1) {
                let m = store.messages[message_index]
                let updates = Object.keys(new_update)
                for (let k of updates) {
                    m[k] = new_update[k]
                }
                store.messages[message_index] = m
            }
        }
    }
})



</script>

<style>


@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/montserrat.ttf');
}


:root {
    --swiper-theme-color: rgb(31, 81, 255) !important;
    --swiper-pagination-bullet-inactive-color: white !important;

    --swiper-pagination-bullet-width: 10px;
    --swiper-pagination-bullet-height: 10px;
}




#app {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
    background-color: white;
    
}
body {
    padding: 0px;
    margin: 0px;
}


a {
    color: inherit;
    text-decoration: none;
    width: fit-content;
}
a:active, a:focus {
    background-color: unset;
    color: unset;
    -webkit-tap-highlight-color: transparent;
}

textarea, input, select {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    resize: none;
}

</style>
