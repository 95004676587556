<template>
    <div class="complete">
        
        <div class="logo">
            <img src="../../assets/images/logo-text.png"/>
        </div>
        <div class="main">
            <div class="dp">
                <img :src="store.picture" v-if="store.picture"/>
                <img src="../../assets/dp.svg" v-else/>

                <div class="camera">
                    <camera :size="'30px'" :style="{'height': '30px'}"/>
                </div>
            </div>
            <div class="input">
                <div class="label">Name</div>
                <input type="text" placeholder="Enter your name" v-model="name">
            </div>
            <div class="input">
                <div class="label">Username</div>
                <input type="text" placeholder="Enter your username" v-model="username">
            </div>
            <!-- <div class="input">
                <div class="label">Phone Number</div>
                <input type="text" placeholder="Enter your phone number">
            </div> -->
            <div class="input">
                <div class="label">Gender</div>
                <select v-model="gender">
                    <option value="" selected disabled> Choose your gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </select>
            </div>
        </div>
        <div class="line"></div>
        <div class="bottom">
            <div class="text">Fill in your details above to setup your account</div>

            <btn_loader :white="true" :height="'42px'" v-if="loading"/>
            <div class="btn" v-else @click="complete_setup()">Complete Registration</div>
        </div>
    </div>
</template>

<script setup>
import Localbase from 'localbase'
let db = new Localbase('tryraffia')
import { store } from '../../store/store.js'


import camera from '../../icons/camera.vue';
import btn_loader from '../../components/util/btn_loader.vue';


import { ref, inject } from 'vue'

import { useRouter } from 'vue-router';
const router = useRouter()

const name = ref('')
const username = ref('')
const gender = ref('')
const loading = ref(false)


import { useToast } from "vue-toastification";
const toast = useToast()
const axios = inject('axios')


const init = () => {
    if (store.registration_complete) {
        router.push('/home')
    }
}
init()


const complete_setup = () => {
    if (name.value == '' || username.value == '' || gender.value == '') {
        toast.error('Make sure all field are filled')
        return
    }
    
    loading.value = true

    axios.post('/onboard/complete_registration', {
        'name': name.value,
        'username': username.value,
        'gender': gender.value
    }).then(data => {
        let r = data.data
        if (r.success) {

            db.collection('auth').doc({ id: 1 }).update({
                'name': name.value,
                'username': username.value,
                'gender': gender.value,
                'contacts': 0,
                'registration_complete': true
            }).then(() => {
                store.name = name
                store.username = username
                store.gender = gender
                store.registration_complete = true

                toast.success('Registration complete!!!')

                setTimeout(() => {
                    router.push('/home')
                }, 1000);
            })
        } else {
            if (r.username) {
                toast.error('This username has already been taken')

                loading.value = false
            }
        }
    })

}

</script>

<style scoped>
    
    .complete {
        position: relative;
        height: 100vh;
        width: 100vw;

        display: flex;
        flex-direction: column;
    }
    .logo {
        padding: 20px;
    }
    .logo img {
        width: 70px;
    }
    

    .main {
        flex: auto;
        /* height: calc(100vh - 180px); */
        padding: 20px;
        box-sizing: border-box;
        overflow: auto;
    }
    .dp {
        width: 100px;
        height: 100px;
        overflow: auto;
        margin: 0 auto;
        margin-bottom: 40px;
        border-radius: 50%;
        background-color: white;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
    }
    .dp img {
        width: 100px;
    }

    .camera {
        width: 100px;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        bottom: 0px;
        left: 0px;

        background-color: rgba(0, 0, 0, 0.5);
        fill: white;
    }

    .input {
        margin-bottom: 40px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -22px;
        left: 10px;
        padding: 0px 5px;
        /* background-color: #001122; */
        font-size: 14px;
        font-weight: 500;

        /* display: none; */
    }
    input, select {
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        background-color: white;
        outline: none;
        color: black;
    }
    ::placeholder {
        color: black;
        opacity: 1;
    }


    .line {
        height: 5px;
        /* background: linear-gradient(to right, var(--main), tomato); */
    }
    .bottom {
        background-color: var(--bg);
        color: white;
        padding: 30px 20px;
        /* border-top: 1px solid #ddd; */
        
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .text {
        font-size: 12px;
        margin-bottom: 10px;
    }
    .btn {
        background-color: white;
        border-radius: 5px;
        width: 100%;
        padding: 12px;
        font-size: 14px;
        font-weight: 600;
        box-sizing: border-box;
        text-align: center;
        color: #333;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        transition: .3s;
    }
    .btn:active {
        transform: scale(.95);
    }
</style>