<template>
    <div class="popup">
        
        <swiper class="myswiper"
            :direction="'vertical'"
            :resistanceRatio="0" 
            :slideToClickedSlide="true"
            :slidesPerView="'auto'"
            autoHeight
            
            @swiper="swiper_ready"
            @activeIndexChange="slide_changed"
            >
            
            <swiper-slide>
                <div class="dark">
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="box">
                    <div class="main">
                        <div class="image">
                            <img :src="props.user.picture"/>
                        </div>
                        <div class="name">{{ props.user.name }}</div>
                        <div class="username">@{{ props.user.username }}</div>
                        <div class="item">
                            <div class="label">Bio</div>
                            <div class="value" v-if="props.user.bio">{{ props.user.bio }}</div>
                            <div class="value" v-else>Not set yet</div>
                        </div>
                        <div class="item">
                            <div class="label">Location</div>
                            <div class="value" v-if="props.user.location">{{ props.user.location }}</div>
                            <div class="value" v-else>Not set yet</div>
                        </div>
                        <div class="item">
                            <div class="label">Birthday</div>
                            <div class="value" v-if="props.user.birthday">{{ props.user.birthday }}</div>
                            <div class="value" v-else>Not set yet</div>
                        </div>
                        <div class="item">
                            <div class="label">Relationship</div>
                            <div class="value" v-if="props.user.relationship">{{ props.user.relationship }}</div>
                            <div class="value" v-else>Not set yet</div>
                        </div>
                        <div class="item">
                            <div class="label">hobbies</div>
                            <div class="value" v-if="props.user.hobbies">{{ props.user.hobbies }}</div>
                            <div class="value" v-else>Not set yet</div>
                        </div>
                    </div>
                    <div class="bottom">
                        <spinner :height="'48px'" v-if="loading"/>
                        <div v-else>
                            <div class="btn" v-if="in_contact">Remove from Contact</div>
                            <div class="btn" v-else @click="add_to_contact()">Add to Contacts</div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script setup>

import Localbase from 'localbase'
let db = new Localbase('tryraffia')
import { store } from '../../store/store.js'


import { Swiper, SwiperSlide } from 'swiper/swiper-vue.mjs';

import spinner from '../util/btn_loader.vue';

import { ref, inject } from 'vue';

const axios = inject('axios')

import { defineProps, defineEmits } from 'vue';
const props = defineProps(['user', 'in_contact'])
const emit = defineEmits(['close', 'added_to_contact'])


var swiper_instance = null;
function swiper_ready(s) {
    swiper_instance = s

    setTimeout(() => {
        swiper_instance.slideNext()
    }, 200);
}

function slide_changed(s) {
    
    console.log('cccc');
    
    if (s.activeIndex == 0) {
        setTimeout(() => {
            emit('close')
        }, 200);
    }
}

const loading = ref(false)


import { useToast } from "vue-toastification";
const toast = useToast()

function add_to_contact() {
    
    loading.value = true

    axios.post('/user/add_to_contact', {
        'user_id': props.user.user_id,
        'is_real': true
    }).then(data => {
        let r = data.data
        if (r.success) {
            store.contacts++
            db.collection('auth').doc({ id: 1 }).update({
                'contacts': store.contacts,
            }).then(() => {
                emit('added_to_contact')

                let new_contact = { ...props.user }
                new_contact['is_real'] = true
                
                db.collection('contacts').add({...props.user}).then(() => {
                    
                    emit('close')
                    toast.success(`${props.user.name} has been added to your contact`)

                    loading.value = false
                })
            })
            
        }
    })
}

</script>

<style scoped>
    .popup {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;

        width: 100%;

        background-color: rgba(0, 0, 0, 0.9);
        color: white;
    }
    .myswiper {

        height: 100vh;
    }
    .dark {
        height: 100vh;
    }
    .box {
        background-color: white;
        color: #333;
        height: 70vh;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        display: flex;
        flex-direction: column;
    }
    .main {
        flex: auto;
        overflow: auto;
        padding: 20px;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100px;
        height: 100px;
        margin: 0 auto;
        border-radius: 50%;
        overflow: auto;
    }
    .image img {
        width: 100px;
    }

    .name {
        font-weight: 700;
        margin-top: 10px;
        text-align: center;
    }
    .username {
        text-align: center;
        color: gray;
        font-size: 14px;
    }
    .item {
        padding: 15px;
        background-color: rgba(0, 0, 0, 0.1);
        margin-top: 20px;
    }
    .label {
        font-size: 12px;
        margin-bottom: 5px;
    }
    .value {
        font-weight: 500;
    }
    .bottom {
        padding: 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    .btn {
        padding: 15px;
        text-align: center;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
    }

</style>