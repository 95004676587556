<template>
    <div class="login">
        
        <div class="logo">
            <img src="../../assets/images/logo-text.png"/>
        </div>

        <div class="center">
            <div class="icon">
                <img src="../../assets/images/logo.png"/>
            </div>
            <div class="title">Welcome</div>
            <div class="text">
                To use Tryraffia, get started by signin in with your Google account below
            </div>
        </div>

        <div class="bottom" v-if="ready">
            <div v-if="loading">
                <spinner :color="'white'"/>
            </div>
            <div class="btns" v-else>
                <div>
                    <div>
                        <GoogleLogin :callback="callback"
                        :buttonConfig="{
                            'text': 'continue_with',
                            'logo_alignment': 'center',
                            'width': width,
                        }"
                        />
                    </div>
                </div>
                <!-- <div class="btn">
                    <phone :size="'20px'" :style="{'height': '20px'}"/>
                    Phone Number
                </div> -->
            </div>

            <div class="click">Terms and Conditions</div>
        </div>
    </div>
</template>

<script setup>
import Localbase from 'localbase'
let db = new Localbase('tryraffia')
import { store } from '../../store/store.js'

import { ref, onMounted, inject } from 'vue'
import { useRouter } from 'vue-router';


// import tooltip from '../../icons/tooltip.vue'
// import phone from '../../icons/phone.vue'
import spinner from '../../components/util/loading.vue';


const axios = inject('axios')
const router = useRouter()


const width = ref('0px')
const ready = ref(false)
const loading = ref(false)


const init = () => {
    if (store.login) {
        if (store.registration_complete) {
            router.push('/home')
        } else {
            router.push('/complete')
        }
    }
}
init()


onMounted(() => {
    width.value = (window.innerWidth - 40) + 'px'
    ready.value = true

    console.log(width.value);
})


import { decodeCredential } from 'vue3-google-login'

const callback = (response) => {
    loading.value = true

    console.log("Handle the response", response)
    
    const userData = decodeCredential(response.credential)
    console.log("Handle the userData", userData)

    axios.post('/onboard/verify_google', {
        'credential': response.credential
    }).then(data => {
        let r = data.data
        if (r.success) {
            if (r.registration_complete) {

                db.collection('auth').add({
                    id: 1,
                    user_id: r.user_id,
                    email: userData.email,
                    picture: userData.picture,
                    username: r.username,
                    name: r.name,
                    gender: r.gender,
                    
                    bio: r.bio,
                    location: r.location,
                    birthday: r.birthday,
                    relationship: r.relationship,
                    phone: r.phone,
                    hobbies: r.hobbies,
                    contacts: r.contacts,

                    registration_complete: true,
                    session: r.session,

                    last_message_id: r.last_message_id,
                    last_message_date: r.last_message_date
                }).then(() => {

                    store.login = true
                    store.registration_complete = true
                    store.user_id = r.user_id
                    store.email = r.email
                    store.username = r.username
                    store.name = r.name
                    store.gender = r.gender
                    store.picture = r.picture
                    
                    store.bio = r.bio,
                    store.location = r.location,
                    store.birthday = r.location,
                    store.relationship = r.relationship,
                    store.phone = r.phone,
                    store.hobbies = r.hobbies
                    store.contacts = r.contacts
                    
                    store.last_message_id = r.last_message_id
                    store.last_message_date = r.last_message_date
                    
                    axios.defaults.headers.common['Auth'] = r.session

                    axios.post('/user/contacts')
                    .then(async data => {
                        let r = data.data
                        if (r.success) {
                            for (let x of r.contacts) {
                                await db.collection('contacts').add(x)
                            }
                            
                            router.push('/home')
                            router.go()
                        }
                    })
                    
                })
            } else {
                
                db.collection('auth').add({
                    id: 1,
                    user_id: r.user_id,
                    email: userData.email,
                    picture: userData.picture,
                    registration_complete: false,
                    session: r.session,
                    last_message_id: 0,
                    last_message_date: 0
                }).then(() => {
                    
                    store.user_id = r.user_id
                    store.email = userData.email
                    store.picture = userData.picture
                    
                    store.last_message_id = 0
                    store.last_message_date = 0
                    
                    axios.defaults.headers.common['Auth'] = r.session
                    
                    router.push('/complete')
                    router.go()
                })
            }
        }
    })
}



</script>

<style scoped>
    
    .login {
        position: relative;
        height: 100vh;
        width: 100vw;


        display: flex;
        align-items: center;
        justify-content: center;
    }
    .logo {
        position: absolute;
        top: 0px;
        left: 0px;
        padding: 20px;
    }
    .logo img {
        width: 70px;
    }


    .center {
        padding: 20px;
        margin-bottom: 100px;
    }
    .icon {
        fill: white;
        text-align: center;
    }
    .icon img {
        width: 80px;
    }
    .title {
        font-size: 25px;
        font-weight: 800;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .text {
        text-align: center;
        opacity: .8;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 10px;
    }

    
    .bottom {
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        padding: 30px 20px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        gap: 20px;

        
        background-color: var(--bg);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .btns {

        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .google_btn {
        background-color: white;
        border-radius: 5px;
        
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn {
        background-color: white;
        border-radius: 5px;
        width: 100%;
        padding: 12px;
        font-size: 14px;
        font-weight: 600;
        box-sizing: border-box;
        color: #001122;
        text-align: center;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        transition: .3s;
    }
    .btn:active {
        transform: scale(.95);
    }

    .click {
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        text-align: center;
        color: white;
    }
</style>