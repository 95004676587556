<template>
    <div class="chat" @click="open_chat()">
        <div class="image">
            <img :src="chat_user.picture" class="dp" alt=""/>
        </div>
        <div class="center">
            <div class="name">
                {{ chat_user.name }}
                
                <div class="status" 
                    :class="[user_status.last_seen != '' ? user_status.online ? 'online' : 'offline' : '']" >
                </div>
            </div>
            <div class="message">
                <div v-if="last_message.sender == store.user_id">
                    You:
                </div>
                <div>{{ last_message.text }}</div>
            </div>
        </div>
        <div>
            <div class="date">{{ last_message_date }}</div>
        </div>
    </div>
</template>

<script setup>
import moment from 'moment';

import Localbase from 'localbase'
let db = new Localbase('tryraffia')
import { store } from '../../store/store';
import { socket } from '../../socket';

import { defineProps, ref, computed, onUnmounted, watch } from 'vue';


import { useRouter } from 'vue-router';
const router = useRouter()

const props = defineProps(['chat'])

const chat_user = ref({})
const last_message = ref({})
const last_message_date = ref('')
const unread = ref(0)

const init = () => {
    let chat = {...props.chat}

    store.status_watch[props.chat.user_id] = {
        "online": false,
        "last_seen": ""
    }

    socket.emit('watch_user_status', {
        'auth': store.auth,
        'watching': chat.user_id
    })

    
    db.collection('contacts').doc({'user_id': chat.user_id}).get()
    .then(doc => {
        chat_user.value = doc

        db.collection(chat.user_id).orderBy('time', 'desc').limit(10).get()
        .then(doc => {
            last_message.value = doc[0]
            last_message_date.value = moment(last_message.value.date).calendar({
                sameDay: 'HH:mm',
                lastDay: 'ddd HH:mm',
                lastWeek: 'ddd HH:mm',
                sameElse: 'DD/MM/YYYY HH:mm'
            })

            for (let x of doc) {
                if (store.user_id != x.sender && !x.seen) {
                    unread.value++
                } else {
                    break
                }
            }
        })
    })
}
init()


const open_chat = () => {
    store.chat = {...props.chat}
    store.chat_user = chat_user.value
    router.push('/chat/' + props.chat.user_id)
}


watch(props.chat, () => {
    
    let chat = {...props.chat}

    unread.value = 0
    
    db.collection(chat.user_id).orderBy('time', 'desc').limit(10).get()
    .then(doc => {
        last_message.value = doc[0]
        last_message_date.value = moment(last_message.value.date).calendar({
            sameDay: 'HH:mm',
            lastDay: 'ddd HH:mm',
            lastWeek: 'ddd HH:mm',
            sameElse: 'DD/MM/YYYY HH:mm'
        })

        for (let x of doc) {
            if (store.user_id != x.sender && !x.seen) {
                unread.value++
            } else {
                break
            }
        }
    })


}, {deep: true})


const user_status = computed(() => {
    return store.status_watch[props.chat.user_id]
})

onUnmounted(() => {
    delete store.status_watch[props.chat.user_id]
    
    socket.emit('un_watch_user_status', {
        'auth': store.auth,
        'user_to_un_watch': props.chat.user_id
    })
})

</script>

<style scoped>
    .chat {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 20px;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .image .dp {
        width: 40px;
    }

    .center {
        flex: auto;
    }
    .name {
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .status {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .offline {
        background-color: tomato;
    }
    .online {
        background-color: var(--main);
    }
    .message {
        display: flex;
        gap: 5px;
        margin-top: 5px;
        font-size: 14px;
    }

    .date {
        font-size: 12px;
        color: gray;
    }
</style>